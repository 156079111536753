/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React, { useState, useEffect, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import navigate from '@utils/navigate';
import PropTypes from 'prop-types';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import { getUser } from '@utils/authV2';
import { productSearch, searchProductsWithNotes } from '@api/productService';
import { getAllWishlistsNames } from '@api/wishlistService';
import { isListNotEmpty } from '@utils/commonUtils';
import { constructUrlBody, urlConstructor, objectConstructor, constructSearchRequestBody, getRelativePath } from '@utils/urlParser';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import Loader from '@common_components/Loader';
import { constants, facetCodes } from '@utils/constants';
import { getPageContent, getTranslatedPageContent, getFacets } from '@utils/contentUtils';
import { UserData } from '@utils/localStorageUtils';
import { globalHistory } from '@reach/router';
import { initialPLPContentCount } from '@src/config/config';

const ProductList = props => {
  const { allSanityPredefinedPage, allAvailableFacets } = useStaticQuery(graphql`
  {
    allSanityPredefinedPage(filter: {pageType: {eq: "plp"}}) {
      nodes {
        _rawTranslations(resolveReferences: {maxDepth: 100})
        pageType
        mozcomLocationId
        mozcomBrandId
        title
        seoDetails {
          title
        }
        pageSpecificProperties {
          contentLimit
        }
      }
    }
    allAvailableFacets {
      nodes {
        mozcomLocationId
        data {
          facetName
          code
          facetValues {
            id
            name
          }
        }
      }
    }
  }  
`);

  const { classification, id } = props;
  const location = globalHistory?.location;

  const queryString = location?.search || '';
  const queryObject = objectConstructor(queryString);
  const pathname = getRelativePath(location?.pathname) || '';
  const cat = id || queryObject?.cat;
  const { key } = queryObject;
  const userDetails = getUser();

  //local States
  const [searchResults, setSearchResults] = useState({
    aggregations: [],
    responseObjects: [],
    totalCount: 0,
    sorts: [],
  });
  const [finalSearchResults, setFinalSearchResults] = useState({
    aggregations: [],
    responseObjects: [],
    totalCount: 0,
    sorts: [],
  });
  const [selectedSort, setSort] = useState(null);
  const [selectedPage, setPage] = useState(0);
  const [selectedAggregations, setSelectedAggregations] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isFilterModalOpen, setFilterModalFlag] = useState(false);
  const [availableWishlists, setAvailableWishlists] = useState([]);
  const [hasNotes, setNotesFlag] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const { TYPE, KEY } = constants;
  const [allFacets, setAllFacets] = useState(getFacets(allAvailableFacets?.nodes)?.map(each => each.data) || []);
  const [contents, setContents] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  const [productsPerPage, setProductsPerPage] = useState(24);
  const [hideUnavailable, setHideUnavailable] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const toggleFilterModal = () => setFilterModalFlag(!isFilterModalOpen);

  const scrollToTop = () => {
    if (window) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const formatAggregations = aggregations => {
    const productRelevanceIndex = aggregations.findIndex(each => each.code === facetCodes.PRODUCT_RELEVANCE);
    const variantRelevanceIndex = aggregations.findIndex(each => each.code === facetCodes.VARIANT_RELEVANCE);
    if (productRelevanceIndex < 0) {
      return aggregations.sort((a, b) => a.displayOrder - b.displayOrder);
    }
    if (variantRelevanceIndex < 0) {
      return aggregations.sort((a, b) => a.displayOrder - b.displayOrder);
    }
    const ProductRelevances = aggregations[productRelevanceIndex];
    const variantRelevances = aggregations[variantRelevanceIndex];
    if (ProductRelevances && isListNotEmpty(ProductRelevances.facetValues)) {
      const newRelevanceFacetValues = ProductRelevances.facetValues.map(value => ({ ...value, name: value.name.replace(/([A-Z])/g, ' $1').trim() }));
      const newRelevances = { ...ProductRelevances, facetValues: newRelevanceFacetValues };
      aggregations.splice(productRelevanceIndex, 1, newRelevances);
    }
    if (variantRelevances && isListNotEmpty(variantRelevances.facetValues)) {
      const newRelevanceFacetValues = variantRelevances.facetValues.map(value => ({ ...value, name: value.name.replace(/([A-Z])/g, ' $1').trim() }));
      const newRelevances = { ...variantRelevances, facetValues: newRelevanceFacetValues };
      aggregations.splice(productRelevanceIndex, 1, newRelevances);
    }
    return aggregations.sort((a, b) => a.displayOrder - b.displayOrder);
  };

  const saveSearchResults = searchResult => {
    const { responseObjects, aggregations: unSortedAggregation, totalCount, sorts } = searchResult || {};
    const sortedAggregations = formatAggregations(unSortedAggregation || []);
    const content = {
      aggregations: sortedAggregations || [],
      responseObjects: responseObjects || [],
      totalCount: totalCount || 0,
      sorts: sorts || [],
    };
    setFinalSearchResults(content);
    setSearchResults(content);
    if (initialPLPContentCount > 0 && isListNotEmpty(responseObjects) && responseObjects.length > initialPLPContentCount && UserData.getPerformance !== 'NORMAL') {
      const initialSearchResultList = [];
      responseObjects.forEach(responseObject => {
        if (initialSearchResultList.length < initialPLPContentCount) {
          initialSearchResultList.push(responseObject);
        }
      });
      setSearchResults({
        aggregations: [],
        responseObjects: initialSearchResultList || [],
        totalCount: totalCount || 0,
        sorts: [],
      });
    }
  };

  const searchProducts = (data, hasNotesFlag) => {
    if (hasNotesFlag) {
      setLoading(true);
      searchProductsWithNotes({ ...data, customerAccount: userDetails?.selectedAccountUniqueId }).then(resposne => {
        setLoading(false);
        if (resposne && resposne.success && resposne.data) {
          saveSearchResults(resposne.data);
        } else {
          saveSearchResults(null);
        }
      });
    } else {
      setLoading(true);
      productSearch(data).then(resposne => {
        setLoading(false);
        if (resposne && resposne.success && resposne.data) {
          saveSearchResults(resposne.data);
        } else {
          saveSearchResults(null);
        }
      });
    }
  };

  const updateUrl = filterData => {
    const filterObject = constructUrlBody(filterData);
    const constructedQuery = urlConstructor(filterObject);
    const url = constructedQuery ? `${pathname}?${constructedQuery}` : pathname;
    navigate(url);
  };

  const getProducts = filters => {
    const {
      selectedAggregations: aggregations,
      page,
      sort,
      searchKey,
      hideUnavailable: hideOutOfStock,
      isClearAll,
      hasNotes: hasNotesFlag,
    } = filters;
    setUpdated(true);
    scrollToTop();
    setSelectedAggregations(aggregations);
    setPage(page);
    setSort(sort);
    setSearchTerm(searchKey);
    setHideUnavailable(!!hideOutOfStock);
    const hasNotesLocal = isClearAll ? false : hasNotesFlag;
    setNotesFlag(!!hasNotesLocal);
    searchProducts(
      {
        categoryId: cat,
        searchTerm: searchKey,
        attributes: aggregations,
        page,
        sort,
        size: productsPerPage,
        hideOutOfStock,
      },
      hasNotesLocal,
    );
    updateUrl({
      page,
      sort,
      key: searchKey,
      aggregations,
      hasNotes: hasNotesLocal,
      cat: id ? null : cat,
      hideUnavailable: hideOutOfStock,
    });
  };

  const getWishlists = () => {
    getAllWishlistsNames().then(response => {
      if (response && response.success && isListNotEmpty(response.data)) {
        setAvailableWishlists(response.data);
      }
    });
  };

  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const localContents = getPageContent(allSanityPredefinedPage?.nodes);
      setContents(getTranslatedPageContent(localContents)?.contents);
      setSeoTitle(localContents?.seoDetails?.title || '');
      const contentLimit = localContents?.pageSpecificProperties?.contentLimit;
      setProductsPerPage(contentLimit || 24);
    }
  }, [allSanityPredefinedPage?.nodes]);

  useEffect(() => {
    if (allAvailableFacets && allAvailableFacets?.nodes) {
      const filteredFacets = getFacets(allAvailableFacets?.nodes);
      const localFacets = filteredFacets?.map(each => each.data);
      setAllFacets(localFacets);
    }
  }, [allAvailableFacets]);

  useEffect(() => {
    getWishlists();
  }, []);

  useEffect(() => {
    if (!isUpdated) {
      setSearchTerm(key);
      constructSearchRequestBody(queryObject, allFacets).then(requestBody => {
        const { page, sort, attributes, hasNotes: hasNotesFlag, hideUnavailable: hideOutOfStock } = requestBody;
        setSort(sort);
        setPage(page || 0);
        setNotesFlag(hasNotesFlag);
        setHideUnavailable(!!hideOutOfStock);
        setSelectedAggregations(attributes);
        const localContents = getPageContent(allSanityPredefinedPage?.nodes);
        searchProducts(
          {
            categoryId: cat,
            searchTerm: key,
            attributes,
            page,
            sort,
            size: localContents?.pageSpecificProperties?.contentLimit || 24,
            hideOutOfStock,
          },
          hasNotesFlag,
        );
      });
    }
    setUpdated(false);
  }, [queryString, cat]);

  useEffect(() => {
    let filterApplied = false;
    if (isListNotEmpty(selectedAggregations)) {
      filterApplied = selectedAggregations.some(each => isListNotEmpty(each?.facetValues));
    }
    setIsFilterApplied(filterApplied || !!hasNotes);
  }, [selectedAggregations, hasNotes]);

  useEffect(() => {
    const customerClassification = UserData.getCustomerClassification();
    if (customerClassification && customerClassification !== classification) {
      const updatedPathname = id ? `/${customerClassification}/product-list/${id}` : `/${customerClassification}/product-list`;
      return navigate(updatedPathname + queryString);
    } if (!customerClassification && classification) {
      const updatedPathname = id ? `/product-list/${id}` : '/product-list';
      return navigate(updatedPathname + queryString);
    }
    return pathname;
  }, [pathname]);

  const showAllComponents = () => {
    setShowAll(true);
    setSearchResults(finalSearchResults);
    UserData.setPerformance('NORMAL');
  };

  useEffect(() => {
    UserData.clearPerformance();
  }, []);

  return (
    <div onMouseEnter={showAllComponents} onScroll={showAllComponents} onMouseOver={showAllComponents}>
      <Layout>
        {showAll && <SEO title={seoTitle} /> }
        {isLoading ? <Loader /> : ''}
        <div className='plp-layout-outer top-shadow'>
          <div className={isLoading ? 'd-none' : ''}>
            {
              isListNotEmpty(contents)
                ? contents.map(content => (
                  <Fragment key={`plp-${content[KEY]}`}>
                    <SanityWidgetComponent
                      widgetType={content[TYPE]}
                      content={content}
                      type='PLP'
                      layoutDir='plp'
                      getProducts={getProducts}
                      selectedAggregations={selectedAggregations}
                      aggregations={searchResults?.aggregations}
                      selectedSort={selectedSort}
                      selectedPage={selectedPage}
                      searchTerm={searchTerm}
                      productsPerPage={productsPerPage}
                      isFilterApplied={isFilterApplied}
                      isFilterModalOpen={isFilterModalOpen}
                      toggleFilterModal={toggleFilterModal}
                      availableWishlists={availableWishlists}
                      hasNotes={hasNotes}
                      getWishlists={getWishlists}
                      availableSorts={searchResults?.sorts}
                      totalCount={searchResults?.totalCount}
                      products={searchResults?.responseObjects}
                      hideUnavailable={hideUnavailable}
                    />
                  </Fragment>
                ))
                : ''
            }
          </div>
        </div>
      </Layout>
    </div>
  );
};

ProductList.defaultProps = {
  classification: '',
  id: '',
};
ProductList.propTypes = {
  classification: PropTypes.string,
  id: PropTypes.string,
};
export default ProductList;

import React from 'react';

import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import NotFound from '@widgets/404';

const browser = typeof window !== 'undefined' && window;

const NotFoundPage = () => browser && (
  <Layout>
    <SEO title='404: Not found' />
    <NotFound />
  </Layout>
);

export default NotFoundPage;

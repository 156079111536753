import React from 'react';
import PropTypes from 'prop-types';

const PublicRouter = ({ component: Component, ...rest }) => <Component {...rest} />;

PublicRouter.defaultProps = {
  location: null,
};

PublicRouter.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
};
export default PublicRouter;

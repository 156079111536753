import React, { Fragment, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ForgotPassword from '@widgets/ForgotPassword';
import SEO from '@widgets/SEO';
import { withTrans } from '@i18n/withTrans';
import { isListNotEmpty } from '@utils/commonUtils';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { constants } from '@utils/constants';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';

const ForgotPasswordPage = () => {
  const { allSanityRetailerPage } = useStaticQuery(graphql`
  {
    allSanityRetailerPage(filter: {pageType: {eq: "forgotPassword"}}) {
      nodes {
        id
        title
        mozcomRetailerId
        _rawTranslations(resolveReferences: {maxDepth: 100})
        seoDetails {
          title
        }
        _type
        pageType
      }
    }
  }
`);

  const [contents, setContents] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  const { TYPE, KEY } = constants;

  useEffect(() => {
    if (isListNotEmpty(allSanityRetailerPage?.nodes)) {
      const localContent = getPageContent(allSanityRetailerPage?.nodes);
      setContents(getTranslatedPageContent(localContent));
      setSeoTitle(localContent?.seoDetails?.title || '');
    }
  }, [allSanityRetailerPage?.nodes]);
  return (
    <>
      <SEO title={seoTitle || 'Forgot Password'} />
      {
        isListNotEmpty(contents?.contents)
          ? contents.contents.map(content => (
            <Fragment key={`forgot-password-${content[KEY]}`}>
              <SanityWidgetComponent
                widgetType={content[TYPE]}
                content={content}
              />
            </Fragment>
          )) : <ForgotPassword />
      }
    </>
  );
};

export default withTrans(ForgotPasswordPage);

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CreatePassword from '@widgets/CreatePassword/layouts/CreatePassword';
import { withTrans } from '@i18n/withTrans';
import navigate from '@utils/navigate';
import customerAccountServices from '@api/customerAccountServices';
import { useTranslation } from 'react-i18next';

const CreatePasswordContainer = ({ token, isTokenValid, content }) => {
  const { t } = useTranslation();
  const [passwordData, setPasswordData] = useState({ currentPassword: '', retypePassword: '' });
  const [error, setError] = useState({ currentPassword: '', retypePassword: '', isValid: '' });
  const [submitting, setSubmitting] = useState(false);

  /**
   * This method is used to handle submit form
   *
   * @param {Object} loginData
   */
  const handleSubmitForm = () => {
    setSubmitting(true);
    const data = {
      password: passwordData.currentPassword,
      token,
    };
    if (token && passwordData.currentPassword) {
      customerAccountServices.setPasswordRequest(data).then(response => {
        setSubmitting(false);
        if (response && response.success && response.data) {
          navigate('/');
        } else {
          setError({ retypePassword: t('createPassword:error_message_unable_to_reset_password') });
        }
      });
    }
  };

  /**
   * This method is used to handle change text
   *
   * @param {String} key
   * @param {Object} e
   */
  const handleChange = (key, e) => {
    setError({ currentPassword: '', retypePassword: '', isValid: '' });
    setPasswordData({ ...passwordData, [key]: e.target.value });
  };

  /**
   * Tjis method is used to validate login form
   *
   * @returns
   */
  const validateForm = () => {
    const regexp = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    let isValid = true;
    let currentPasswordError = '';
    let retypePasswordError = '';
    if (passwordData.currentPassword === '') {
      currentPasswordError = t('createPassword:error_message_enter_new_password');
      isValid = false;
    } else if (passwordData.currentPassword.length < 6) {
      currentPasswordError = t('createPassword:error_message_must_have_n_letter');
      isValid = false;
    } else if (isValid && !regexp.test(passwordData.currentPassword)) {
      currentPasswordError = t('createPassword:error_message_include_charactor');
      isValid = false;
    }
    if (isValid && passwordData.retypePassword === '') {
      retypePasswordError = t('createPassword:error_message_retype_new_password');
      isValid = false;
    } else if (isValid && passwordData.currentPassword !== passwordData.retypePassword) {
      retypePasswordError = t('createPassword:error_message_password_not_match');
      isValid = false;
    }
    setError({ currentPassword: currentPasswordError, retypePassword: retypePasswordError });
    return isValid;
  };

  /**
   * This method is used to handle submit form
   *
   */
  const handleSubmit = event => {
    event.preventDefault();
    if (validateForm(passwordData)) {
      handleSubmitForm();
    }
  };

  return (
    <>
      <CreatePassword
        handleSubmitForm={handleSubmitForm}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        passwordData={passwordData}
        error={error}
        submitting={submitting}
        isTokenValid={isTokenValid}
        content={content}
      />
    </>
  );
};

CreatePasswordContainer.defaultProps = {
  content: {},
  isTokenValid: false,
  token: '',
};

CreatePasswordContainer.propTypes = {
  content: PropTypes.objectOf(PropTypes.any),
  token: PropTypes.string,
  isTokenValid: PropTypes.bool,
};

export default withTrans(CreatePasswordContainer);

import React, { useState, useEffect, Fragment } from 'react';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import { isListNotEmpty } from '@utils/commonUtils';
import { constants } from '@utils/constants';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { useStaticQuery, graphql } from 'gatsby';

const VerifyAddressPage = () => {
  const { allSanityPredefinedPage, allSanityBrand } = useStaticQuery(graphql`
  {
    allSanityPredefinedPage(filter: {pageType: {eq: "verifyAddress"}}) {
      nodes {
        _rawTranslations(resolveReferences: {maxDepth: 10})
        pageType
        mozcomLocationId
        mozcomBrandId
        title
        seoDetails {
          title
        }
      }
    }
    allSanityBrand {
      nodes {
        mozcomBrandId
        title
        brandLogo {
          asset {
            url
          }
        }
      }
    }
  }
`);
  const { TYPE, KEY } = constants;
  const [contents, setContent] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const content = getPageContent(allSanityPredefinedPage?.nodes);
      setContent(getTranslatedPageContent(content));
      setSeoTitle(content?.seoDetails?.title || '');
    }
  }, [allSanityPredefinedPage, allSanityPredefinedPage?.nodes]);
  return (
    <Layout page='checkout-address'>
      <SEO title={seoTitle} />
      {
        isListNotEmpty(contents?.contents)
          ? contents.contents.map(content => (
            <Fragment key={`verifyAddress-${content[KEY]}`}>
              <SanityWidgetComponent
                widgetType={content[TYPE]}
                content={content}
                brands={allSanityBrand?.nodes}
              />
            </Fragment>
          ))
          : ''
      }
    </Layout>
  );
};
export default VerifyAddressPage;

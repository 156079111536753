/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * SubAccount Container
 *
 * @author Afsal Mamaaz
 *
 */

import React, { useState, useEffect } from 'react';
import { getUser, logout, setUser, getUserDetails, updateTokenWithB2BDepartment } from '@utils/auth';
import { withTrans } from '@i18n/withTrans';
import navigate from '@utils/navigate';
import { isListNotEmpty } from '@utils/commonUtils';
import SubAccount from '../layouts/SubAccountBeta';

const SubAccountContainer = () => {
  const currentUser = getUser();
  const [subAccountList, setSubAccounts] = useState(null);
  const [filteredSubAccounts, setFilteresSubAccounts] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedSubAccount, setSubAccount] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const toggle = () => {
    setSearchText('');
    setFilteresSubAccounts(subAccountList);
    setDropdownOpen(prevState => !prevState);
  };

  useEffect(() => {
    //Generating sub account code from account name
    if (isListNotEmpty(currentUser?.subAccounts)) {
      currentUser.subAccounts.map(item => {
        const updatedItem = item;
        const { name } = updatedItem;
        const matches = name.match(/\b(\w)/g);
        const firstlettersOfWords = matches.join('');
        updatedItem.subAccountCode = firstlettersOfWords;
        return updatedItem;
      });
      setSubAccounts(currentUser.subAccounts);
      setFilteresSubAccounts(currentUser.subAccounts);
      setSearchText('');
    }
  }, []);

  /**
   * This method is used to handle signout click
   *
   */
  const handleSignOut = event => {
    event.preventDefault();
    if (logout) {
      logout();
    }
  };

  const handleSubAccountClick = async (subAccountId, selectedAccountUniqueId) => {
    if (subAccountId) {
      setSubmitting(true);
      setSearchText('');
      currentUser.selectedAccount = subAccountId;
      currentUser.selectedAccountUniqueId = selectedAccountUniqueId;
      await updateTokenWithB2BDepartment(subAccountId);
      await getUserDetails(subAccountId);
      setUser(currentUser);
      setSubmitting(false);
      navigate('/');
    }
  };

  const handleTypeAhead = searchKey => {
    if (typeof searchKey === 'string') {
      setSearchText(searchKey);
      const filteredValues = subAccountList.filter(each => each.name.toLowerCase().search(searchKey.toLowerCase()) > -1);
      setFilteresSubAccounts(filteredValues);
    }
  };

  return (
    <>
      <SubAccount
        subAccounts={subAccountList}
        handleSignOut={handleSignOut}
        handleSubAccountClick={handleSubAccountClick}
        filteredSubAccounts={filteredSubAccounts}
        handleTypeAhead={handleTypeAhead}
        searchText={searchText}
        selectedSubAccount={selectedSubAccount}
        setSubAccount={setSubAccount}
        dropdownOpen={dropdownOpen}
        toggle={toggle}
        submitting={submitting}
      />
    </>
  );
};

export default withTrans(SubAccountContainer);

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Product Details
 *
 * @author Anokh J Ajai
 *
 */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { UserData } from '@utils/localStorageUtils';
import { constants, defaultColorCodes } from '@utils/constants';
import { isListNotEmpty, showProduct } from '@utils/commonUtils';
import Container from '@core/Container';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { spreadPdpSanityWidgets } from '@utils/pdpUtils';
import { getProductDetailsFromOcctoo, getAllProducts, excludedColorVariants } from '@api/productService';
import NotFound from '@widgets/404';
import { initialPDPContentCount } from '@src/config/config';

const PDPPage = props => {
  const { pageContext, id } = props;

  const { allSanityPredefinedPage } = useStaticQuery(graphql`
  {
    allSanityPredefinedPage(filter: {pageType: {eq: "pdp"}}) {
      nodes {
        pageType
        title
        mozcomBrandId
        mozcomLocationId
        _rawTranslations
        seoDetails {
          title
        }
      }
    }
  }
`);
  const { TYPE, KEY } = constants;
  const [showAll, setShowAll] = useState(!initialPDPContentCount > 0);
  const [initialContent, setInitialContent] = useState(null);
  const [finalContent, setFinalContent] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  const [productData, setProductData] = useState(pageContext);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const currentSiteData = getPageContent(allSanityPredefinedPage.nodes);
      const translatedSiteData = getTranslatedPageContent(currentSiteData);
      const localeContents = currentSiteData && isListNotEmpty(translatedSiteData?.contents) ? translatedSiteData.contents : [];
      const contents = spreadPdpSanityWidgets(localeContents);
      const initialContentList = [];
      const finalContentList = [];
      if (isListNotEmpty(contents) && contents?.length > 0) {
        contents?.forEach(contentElement => {
          if (initialContentList.length < initialPDPContentCount) {
            initialContentList.push(contentElement);
          } else {
            finalContentList.push(contentElement);
          }
        });
      }
      setInitialContent(initialContentList);
      setFinalContent(finalContentList);
      setSeoTitle(currentSiteData?.seoDetails?.title || '');
    }
  }, [allSanityPredefinedPage, allSanityPredefinedPage?.nodes]);

  const populateOcctoResult = (attribute, productDetails) => {
    const occtooResult = {};
    const variantImage = attribute?.landingPageBaseUrl;
    const medias = [];
    if (variantImage) {
      medias.push({ url: variantImage, subType: 'Main' });
    }
    occtooResult.media = medias;
    const { name } = productDetails || {};
    occtooResult.name = name;
    return occtooResult;
  };

  const populateDataById = isSiteSpecificDataMissing => {
    if (id && (!pageContext || isSiteSpecificDataMissing)) {
      const searchableId = id.split('-')[0];
      setLoading(true);
      getAllProducts(0, 1, null, null, null, null, [searchableId])
        .then(async response => {
          setLoading(false);
          if (response && response.success
            && response.data && response.data.responseObjects) {
            const [product] = response.data.responseObjects;
            if (isListNotEmpty(product?.colorAttributes)) {
              let [attribute] = product.colorAttributes.filter(colorAttribute => id === colorAttribute?.externalId);
              if (!attribute) {
                [attribute] = product.colorAttributes.filter(colorAttribute => defaultColorCodes.includes(colorAttribute?.name));
              }
              if (attribute) {
                const data = { productDetails: product };
                const { externalId } = attribute;
                setLoading(true);
                const occtooRetryResponse = await getProductDetailsFromOcctoo(externalId);
                setLoading(false);
                if (occtooRetryResponse && occtooRetryResponse.data && isListNotEmpty(occtooRetryResponse.data.results)) {
                  const [result] = occtooRetryResponse.data.results;
                  data.variantDetails = { attribute, occtooResult: result };
                } else {
                  data.variantDetails = {
                    attribute,
                    occtooResult: populateOcctoResult(attribute, product),
                  };
                }
                setProductData(data);
              }
            }
          }
        }).catch(() => null).finally(() => { setLoading(false); });
    }
  };

  const populateDataFromContextData = async () => {
    setLoading(true);
    if (pageContext) {
      const product = { ...pageContext };
      const { variantDetails, productDetails } = product;
      if (!productDetails) {
        populateDataById(true);
      }
      if (productDetails) {
        excludedColorVariants().then(response => {
          const excludedVariants = response.data;
          let availableColorAttributes = [];
          const { colorAttributes } = productDetails;
          if (colorAttributes != null && isListNotEmpty(colorAttributes) && isListNotEmpty(excludedVariants)) {
            availableColorAttributes = colorAttributes.filter(colorAttribute => !excludedVariants.includes(colorAttribute.externalId));
            productDetails.colorAttributes = availableColorAttributes;
          }
        });
      }
      if (variantDetails) {
        const { occtooResult, attribute } = variantDetails;
        if (!occtooResult) {
          const { externalId } = attribute;
          const occtooRetryResponse = await getProductDetailsFromOcctoo(externalId);
          if (occtooRetryResponse && occtooRetryResponse.data && isListNotEmpty(occtooRetryResponse.data.results)) {
            const [result] = occtooRetryResponse.data.results;
            product.variantDetails = { ...variantDetails, occtooResult: result };
          } else {
            product.variantDetails = {
              ...variantDetails,
              occtooResult: populateOcctoResult(attribute, productDetails),
            };
          }
        }
      }
      setProductData(product);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (pageContext && !showProduct(pageContext.productDetails)) {
      navigate('/404');
    }
    if (pageContext) {
      populateDataFromContextData();
    } else if (id) {
      populateDataById();
    }
  }, [pageContext, id]);

  const getPropertyValue = value => {
    let convertedValue = value;
    if (value === true || value === 'true') {
      convertedValue = 'Yes';
    } else if (value === false || value === 'false') {
      convertedValue = 'No';
    }
    if (value && Array.isArray(value)) {
      convertedValue = value.join(', ');
    }
    return convertedValue;
  };

  const showAllComponents = () => {
    if (!showAll) {
      setShowAll(true);
      UserData.setPerformance('NORMAL');
    }
  };

  useEffect(() => {
    UserData.clearPerformance();
  }, []);

  return (
    <div onMouseEnter={showAllComponents} onScroll={showAllComponents} onMouseOver={showAllComponents}>
      <Layout>
        {showAll && <SEO title={seoTitle} />}
        <div className='pdp-layout-outer top-shadow'>
          {!isLoading && (productData && isListNotEmpty(initialContent)
            ? initialContent.map(content => (
              <Fragment key={`pdp-${content[KEY]}`}>
                <Container>
                  <SanityWidgetComponent
                    widgetType={content[TYPE]}
                    content={content}
                    pageContext={productData}
                    getPropertyValue={getPropertyValue}
                  />
                </Container>
              </Fragment>
            ))
            : <NotFound />
          )}
          {!isLoading && productData && isListNotEmpty(finalContent) && (
            showAll ? finalContent.map(content => (
              <Fragment key={`pdp-${content[KEY]}`}>
                <Container>
                  <SanityWidgetComponent
                    widgetType={content[TYPE]}
                    content={content}
                    pageContext={productData}
                    getPropertyValue={getPropertyValue}
                  />
                </Container>
              </Fragment>
            )) : ''
          )}
        </div>
      </Layout>
    </div>
  );
};

PDPPage.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
};
export default PDPPage;

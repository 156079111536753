/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Login Container
 *
 * @author Afsal Mamaaz
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ForgotPassword from '@widgets/ForgotPassword/layouts/ForgotPassword';
import customerAccountServices from '@api/customerAccountServices';
import { useTranslation } from 'react-i18next';

const ForgotPasswordContainer = ({ content }) => {
  const { t } = useTranslation();
  const [emailID, setEmailID] = useState('');
  const [error, setError] = useState({ emailId: '', isValid: '' });
  const [submitting, setSubmitting] = useState(false);
  const [sendForgetPasswordLink, setSendForgetPasswordLink] = useState(false);

  /**
   * This method is used to validate login form
   *
   * @returns
   */
  const validateForm = () => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = true;
    let emailIdError = '';
    if (emailID === '' && isValid) {
      emailIdError = t('forgotPassword:error_message_field_mandatory');
      isValid = false;
    } else if (!regexp.test(emailID)) {
      emailIdError = t('forgotPassword:error_message_invalid_email_format');
      isValid = false;
    }
    setError({ emailId: emailIdError });
    return isValid;
  };

  /**
   * This method is used to handle change text
   *
   * @param {String} key
   * @param {Object} e
   */
  const handleChange = (key, e) => {
    setError({ emailId: '', isValid: '' });
    setEmailID(e.target.value);
  };

  /**
   * This method is used to handle submit form
   *
   * @param {Object} loginData
   */
  const handleSubmitForm = () => {
    setSubmitting(true);
    const data = {
      email: emailID,
    };
    customerAccountServices.forgotPasswordRequest(data).then(response => {
      setSubmitting(false);
      if (response && response.success) {
        if (response.data) {
          setSendForgetPasswordLink(true);
        } else {
          setError({ emailId: t('forgotPassword:error_message_failed_to_send_link') });
        }
      } else {
        setError({ emailId: t('forgotPassword:error_message_failed_to_send_link') });
      }
    });
  };

  /**
   * This method is used to handle submit form
   *
   */
  const handleSubmit = event => {
    event.preventDefault();
    if (validateForm(emailID)) {
      handleSubmitForm();
    }
  };

  return (
    <>
      <ForgotPassword
        handleSubmitForm={handleSubmitForm}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        emailID={emailID}
        error={error}
        submitting={submitting}
        sendForgetPasswordLink={sendForgetPasswordLink}
        content={content}
      />
    </>
  );
};
ForgotPasswordContainer.defaultProps = {
  content: {},
};

ForgotPasswordContainer.propTypes = {
  content: PropTypes.objectOf(PropTypes.any),
};
export default ForgotPasswordContainer;

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React from 'react';
import PropTypes from 'prop-types';
import navigate from '@utils/navigate';
import { isLoggedIn } from '@utils/authV2';
import { UserData } from '@utils/localStorageUtils';
import { unRestrictedPaths } from '@utils/constants';

const PrivateRoute = ({ component: Component, location, path, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== '/login') {
    // If we’re not logged in, redirect to the login page.
    navigate('/login');
    return null;
  }
  if (isLoggedIn() && !unRestrictedPaths.includes(path) && !location.pathname.includes(UserData.getBaseUrl())) {
    navigate('/switch-account');
  }
  return <Component {...rest} />;
};

PrivateRoute.defaultProps = {
  location: null,
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  path: PropTypes.string.isRequired,
};

export default PrivateRoute;

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */
import React, { useState, useEffect, Fragment } from 'react';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import { useStaticQuery, graphql } from 'gatsby';
import { isListNotEmpty } from '@utils/commonUtils';
import { constants } from '@utils/constants';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';

const MyAccountPage = () => {
  const { allSanityPredefinedPage } = useStaticQuery(graphql`
    {
      allSanityPredefinedPage(filter: {pageType: {eq: "profile"}}) {
        nodes {
          _rawTranslations(resolveReferences: {maxDepth: 100})
          pageType
          mozcomLocationId
          mozcomBrandId
          title
          seoDetails {
            title
          }
        }
      }
    }
  `);
  const { TYPE, KEY } = constants;
  const [contents, setContent] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');

  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const content = getPageContent(allSanityPredefinedPage.nodes);
      setContent(getTranslatedPageContent(content));
      setSeoTitle(content?.seoDetails?.title || '');
    }
  }, [allSanityPredefinedPage, allSanityPredefinedPage?.nodes]);
  return (
    <Layout>
      <SEO title={seoTitle} />
      {
        isListNotEmpty(contents?.contents)
          ? contents.contents.map(content => (
            <Fragment key={`myAccount-${content[KEY]}`}>
              <SanityWidgetComponent
                widgetType={content[TYPE]}
                content={content}
              />
            </Fragment>
          )) : ''
      }
    </Layout>
  );
};

export default MyAccountPage;

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */
import React, { useState, useEffect, Fragment } from 'react';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import { useStaticQuery, graphql } from 'gatsby';
import { isListNotEmpty } from '@utils/commonUtils';
import { constants } from '@utils/constants';
import { UserData } from '@utils/localStorageUtils';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import { getPageContent, getTranslatedPageContent, getZendeskURL } from '@utils/contentUtils';

const HelpPage = () => {
  const { allSanityPredefinedPage, allSanityRetailerPage } = useStaticQuery(graphql`
    {
      allSanityPredefinedPage(filter: {pageType: {eq: "help"}}) {
        nodes {
          _rawTranslations(resolveReferences: {maxDepth: 100})
          pageType
          mozcomLocationId
          mozcomBrandId
          title
          seoDetails {
            title
          }
          pageSpecificProperties {
            zenDeskURL
          }
        }
      }
      allSanityRetailerPage(filter: {pageType: {eq: "help"}}) {
        nodes {
          id
          mozcomRetailerId
          _rawTranslations(resolveReferences: {maxDepth: 100})
          seoDetails {
            title
          }
          _type
          pageType
        }
      }
    }
  `);
  const { TYPE, KEY } = constants;
  const [contents, setContent] = useState(null);
  const [title, setTitle] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [zenDeskURL, setZendeskURL] = useState('');
  useEffect(() => {
    const brandId = UserData.getBrandId();
    const locationId = UserData.getLocationId();
    const nodes = brandId && locationId ? allSanityPredefinedPage?.nodes : allSanityRetailerPage?.nodes;
    if (isListNotEmpty(nodes)) {
      const content = getPageContent(nodes);
      setContent(getTranslatedPageContent(content));
      setTitle(content?.title || '');
      setSeoTitle(content?.seoDetails?.title || '');
      setZendeskURL(getZendeskURL(content, allSanityPredefinedPage?.nodes));
    }
  }, [allSanityPredefinedPage, allSanityPredefinedPage?.nodes, allSanityRetailerPage?.nodes]);
  return (
    <Layout>
      <SEO title={seoTitle} zenDeskURL={zenDeskURL} />
      <div className='help-layout-outer top-shadow'>
        <div className='sub-header'>
          <h4 className='sub-heading'>{title}</h4>
        </div>
        {
          isListNotEmpty(contents?.contents)
            ? contents.contents.map(content => (
              <Fragment key={`help-${content[KEY]}`}>
                <SanityWidgetComponent
                  widgetType={content[TYPE]}
                  content={content}
                />
              </Fragment>
            ))
            : ''
        }
      </div>
    </Layout>
  );
};

export default HelpPage;

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import { withTrans } from '@i18n/withTrans';
import { isListNotEmpty } from '@utils/commonUtils';
import { constants } from '@utils/constants';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import SEO from '@widgets/SEO';
import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment, useEffect, useState } from 'react';

const SwitchAccount = () => {
  const { allSanityRetailerPage, allAvailableLocations, allSanityHeader } = useStaticQuery(graphql`
  {
    allSanityRetailerPage(filter: {pageType: {eq: "switchAccount"}}) {
      nodes {
        id
        title
        mozcomRetailerId
        _rawTranslations(resolveReferences: {maxDepth: 100})
        seoDetails {
          title
        }
        _type
        pageType
      }
    }
    allAvailableLocations {
      nodes {
        data {
          brandId
          locationId
          brandName
          locationName
          storefrontRoute
          locale
          market
        }
      }
    }
    allSanityHeader {
      nodes {
        mozcomLocationId
        mozcomBrandId
        _rawTranslations(resolveReferences: {maxDepth: 10})
      }
    }
  }
`);

  const [contents, setContents] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  const { TYPE, KEY } = constants;

  useEffect(() => {
    if (isListNotEmpty(allSanityRetailerPage?.nodes)) {
      const localContent = getPageContent(allSanityRetailerPage?.nodes);
      setContents(getTranslatedPageContent(localContent));
      setSeoTitle(localContent?.seoDetails?.title || '');
    }
  }, [allSanityRetailerPage?.nodes]);
  return (
    <>
      <SEO title={seoTitle} />
      {
        isListNotEmpty(contents?.contents)
          ? contents.contents.map(content => (
            <Fragment key={`switch-account-${content[KEY]}`}>
              <SanityWidgetComponent
                widgetType={content[TYPE]}
                content={content}
                availableSites={allAvailableLocations?.nodes}
                headerContents={allSanityHeader?.nodes || []}
              />
            </Fragment>
          )) : ''
      }
    </>
  );
};

export default withTrans(SwitchAccount);

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 *
 */
import React, { useState, useEffect, Fragment } from 'react';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import { useStaticQuery, graphql } from 'gatsby';
import { isListNotEmpty } from '@utils/commonUtils';
import { constants } from '@utils/constants';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { UserData } from '@utils/localStorageUtils';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';

const PolicyLayout = () => {
  const { allSanityPredefinedPage, allSanityRetailerPage } = useStaticQuery(graphql`
  {
    allSanityRetailerPage(filter: {pageType: {eq: "privacyPolicy"}}) {
      nodes {
        id
        mozcomRetailerId
        _rawTranslations(resolveReferences: {maxDepth: 100})
        seoDetails {
          title
        }
        _type
        pageType
      }
    }
    allSanityPredefinedPage(filter: {pageType: {eq: "privacyPolicy"}}) {
      nodes {
        _rawTranslations(resolveReferences: {maxDepth: 10})
        pageType
        mozcomLocationId
        mozcomBrandId
        title
        seoDetails {
          title
        }
      }
    }
  }
`);

  const [selectedLanguage, setSelectedLanguage] = useState('de_DE');

  const switchLanguage = () => {
    if (selectedLanguage === 'en_DE') {
      setSelectedLanguage('de_DE');
    } else {
      setSelectedLanguage('en_DE');
    }
  };

  const { TYPE, KEY } = constants;
  const [contents, setContent] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  useEffect(() => {
    const brandId = UserData.getBrandId();
    const locationId = UserData.getLocationId();
    const nodes = brandId && locationId ? allSanityPredefinedPage?.nodes : allSanityRetailerPage?.nodes;
    if (nodes) {
      const content = getPageContent(nodes);
      setContent(getTranslatedPageContent(content, selectedLanguage));
      setSeoTitle(content?.seoDetails?.title || '');
    }
  }, [selectedLanguage, allSanityPredefinedPage?.nodes, allSanityRetailerPage?.nodes]);
  return (
    <Layout>
      <SEO title={seoTitle} />
      {
        isListNotEmpty(contents?.contents)
          ? contents.contents.map(content => (
            <Fragment key={`policy-${content[KEY]}`}>
              <SanityWidgetComponent
                widgetType={content[TYPE]}
                content={content}
                switchLanguage={switchLanguage}
                selectedLanguage={selectedLanguage}
              />
            </Fragment>
          ))
          : (
            <SanityWidgetComponent
              widgetType='titleWithLanguageSwitch'
              content
              switchLanguage={switchLanguage}
              selectedLanguage={selectedLanguage}
            />
          )
      }
    </Layout>
  );
};
export default PolicyLayout;

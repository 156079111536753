/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */

import React, { useState, useEffect, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import Loader from '@common_components/Loader';
import { getAllOrderProposals } from '@api/orderProposalService';
import { getUser } from '@utils/authV2';
import { constants } from '@utils/constants';
import { isListNotEmpty } from '@utils/commonUtils';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { storeOrderProposalsInLocalStorage } from '@src/config/config';
import { UserData } from '@utils/localStorageUtils';

const OrderProposalLayout = () => {
  const { allSanityPredefinedPage } = useStaticQuery(graphql`
  {
    allSanityPredefinedPage(filter: {pageType: {eq: "orderProposal"}}) {
      nodes {
        _rawTranslations(resolveReferences: {maxDepth: 100})
        pageType
        mozcomLocationId
        mozcomBrandId
        title
        seoDetails {
          title
        }
      }
    }
  }
`);
  const { email } = UserData.getUser() || {};
  const [orderProposals, setOrderProposals] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getOrderProposals = () => {
    const userDetails = getUser();
    if (userDetails) {
      setLoading(true);
      // get order proposals from the local storage
      if (storeOrderProposalsInLocalStorage) {
        const orderProposalsFromLocalStorage = JSON.parse(UserData.getOrderProposals());
        if (orderProposalsFromLocalStorage) {
          setLoading(false);
          setOrderProposals(orderProposalsFromLocalStorage);
        }
      }
      getAllOrderProposals().then(response => {
        setLoading(false);
        if (response && isListNotEmpty(response.data)) {
          setOrderProposals(response.data.sort((a, b) => (new Date(b.orderDate) - new Date(a.orderDate))));
        } else {
          setOrderProposals(null);
        }
      });
    }
  };

  useEffect(() => {
    getOrderProposals();
  }, []);

  const { TYPE, KEY } = constants;
  const [contents, setContent] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const content = getPageContent(allSanityPredefinedPage.nodes);
      setContent(getTranslatedPageContent(content));
      setSeoTitle(content?.seoDetails?.title || '');
    }
  }, [allSanityPredefinedPage?.nodes]);
  return (
    <Layout>
      <SEO title={seoTitle} />
      {isLoading ? <Loader /> : ''}
      <div className={isLoading ? 'd-none' : ''}>
        <div class='order-proposal-filter-outer top-shadow bg-dark-snow'>
          {
            isListNotEmpty(contents?.contents)
              ? contents.contents.map(content => (
                <Fragment key={`order-proposals-${content[KEY]}`}>
                  <SanityWidgetComponent
                    widgetType={content[TYPE]}
                    content={content}
                    orderProposals={orderProposals}
                    getOrderProposals={getOrderProposals}
                  />
                </Fragment>
              ))
              : null
          }
        </div>
      </div>
    </Layout>
  );
};

export default OrderProposalLayout;

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Reset Password Container
 *
 * @author Afsal Mamaaz
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ResetPassword from '@widgets/ResetPassword/layouts/ResetPassword';
import { withTrans } from '@i18n/withTrans';
import navigate from '@utils/navigate';
import customerAccountServices from '@api/customerAccountServices';
import { useTranslation } from 'react-i18next';

const ResetPasswordContainer = ({ token, isTokenValid, content }) => {
  const { t } = useTranslation();
  const [resetData, setLoginData] = useState({ currentPassword: '', retypePassword: '' });
  const [error, setError] = useState({ currentPassword: '', retypePassword: '', isValid: '' });
  const [submitting, setSubmitting] = useState(false);

  /**
   * This method is used to handle submit form
   *
   * @param {Object} loginData
   */
  const handleSubmitForm = () => {
    setSubmitting(true);
    const data = {
      password: resetData.currentPassword,
      token,
    };
    if (token && resetData.currentPassword) {
      customerAccountServices.resetPasswordRequest(data).then(response => {
        setSubmitting(false);
        if (response && response.success && response.data) {
          navigate('/');
        } else {
          setError({ retypePassword: t('resetPassword:error_message_unable_to_reset_password') });
        }
      });
    }
  };

  /**
   * This method is used to handle change text
   *
   * @param {String} key
   * @param {Object} e
   */
  const handleChange = (key, e) => {
    setError({ currentPassword: '', retypePassword: '', isValid: '' });
    setLoginData({ ...resetData, [key]: e.target.value });
  };

  /**
   * Tjis method is used to validate login form
   *
   * @returns
   */
  const validateForm = () => {
    const regexp = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    let isValid = true;
    let currentPasswordError = '';
    let retypePasswordError = '';
    if (resetData.currentPassword === '') {
      currentPasswordError = t('resetPassword:error_message_enter_new_password');
      isValid = false;
    } else if (resetData.currentPassword.length < 6) {
      currentPasswordError = t('resetPassword:error_message_must_have_n_letter');
      isValid = false;
    } else if (isValid && !regexp.test(resetData.currentPassword)) {
      currentPasswordError = t('resetPassword:error_message_include_charactor');
      isValid = false;
    }
    if (isValid && resetData.retypePassword === '') {
      retypePasswordError = t('resetPassword:error_message_retype_new_password');
      isValid = false;
    } else if (isValid && resetData.currentPassword !== resetData.retypePassword) {
      retypePasswordError = t('resetPassword:error_message_password_not_match');
      isValid = false;
    }
    setError({ currentPassword: currentPasswordError, retypePassword: retypePasswordError });
    return isValid;
  };

  /**
   * This method is used to handle submit form
   *
   */
  const handleSubmit = event => {
    event.preventDefault();
    if (validateForm(resetData)) {
      handleSubmitForm();
    }
  };

  return (
    <>
      <ResetPassword
        handleSubmitForm={handleSubmitForm}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        resetData={resetData}
        error={error}
        submitting={submitting}
        isTokenValid={isTokenValid}
        content={content}
      />
    </>
  );
};
ResetPasswordContainer.defaultProps = {
  content: {},
  isTokenValid: false,
  token: '',
};

ResetPasswordContainer.propTypes = {
  token: PropTypes.string,
  isTokenValid: PropTypes.bool,
  content: PropTypes.objectOf(PropTypes.any),
};

export default withTrans(ResetPasswordContainer);

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */
import React, { useState, useEffect, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import Loader from '@common_components/Loader';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import fenixIntegrationService from '@api/fenixIntegrationService';
import { productSearch } from '@api/productService';
import { UserData } from '@utils/localStorageUtils';
import { constants, catalogueTypes, purchaseErrors } from '@utils/constants';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import { isListNotEmpty, getPDPUrlV2 } from '@utils/commonUtils';

const OrderDetail = props => {
  const { allSanityPredefinedPage } = useStaticQuery(graphql`
   {
     allSanityPredefinedPage(filter: {pageType: {eq: "orderDetails"}}) {
       nodes {
         _rawTranslations(resolveReferences: {maxDepth: 100})
         pageType
         mozcomLocationId
         mozcomBrandId
         title
         seoDetails {
           title
         }
       }
     }
   }
 `);

  const { TYPE, KEY } = constants;
  const [contents, setContent] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');

  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const content = getPageContent(allSanityPredefinedPage.nodes);
      setContent(getTranslatedPageContent(content));
      setSeoTitle(content?.seoDetails?.title || '');
    }
  }, [allSanityPredefinedPage, allSanityPredefinedPage?.nodes]);
  const { pageProps } = props;
  const location = pageProps?.location;
  const { state } = location || {};
  const { preOrder, orders } = state || false;
  const { parseUrl } = queryString;
  const { query } = parseUrl(location?.search || '');
  const { orderNo, orderRefNo } = query || {};
  const [order, setOrder] = useState(orders);
  const [isLoading, setLoading] = useState(false);

  const getProductRePurchaseStatus = orderData => {
    const { brandCode, salesOriginId, products } = orderData;
    const brandConflict = UserData.getBrandId() ? UserData.getBrandId() !== brandCode : true;
    const orderCatalogType = salesOriginId && salesOriginId === 'PRE' ? catalogueTypes.PRE_ORDER : catalogueTypes.RE_ORDER;
    const catalogTypeConflict = orderCatalogType && salesOriginId ? UserData.getCatalogueType() !== orderCatalogType : true;
    if (isListNotEmpty(products) && !brandConflict && !catalogTypeConflict) {
      const productIdsForSearch = [];
      products.forEach(product => {
        if (!productIdsForSearch.includes(product.productId)) {
          productIdsForSearch.push(product.productId);
        }
      });
      const searchRequest = {
        categoryId: null,
        searchTerm: null,
        attributes: null,
        page: null,
        sort: null,
        size: products.length,
        hideOutOfStock: false,
        productIds: productIdsForSearch,
      };
      productSearch(searchRequest).then(response => {
        if (response && response.success && response.data && isListNotEmpty(response.data.responseObjects)) {
          const { responseObjects } = response.data;
          const newProducts = products.map(product => {
            let availableToPurchase = false;
            const newProduct = { ...product };
            const { productId, colorId } = product;
            responseObjects.forEach(object => {
              const { uniqueId, colorAttributes } = object;
              if (uniqueId === productId && isListNotEmpty(colorAttributes)) {
                availableToPurchase = true;
                let PDPUrl = getPDPUrlV2(colorAttributes[0]?.id, productId);
                colorAttributes.forEach(colorAttribute => {
                  if (colorId === colorAttribute.id) {
                    PDPUrl = getPDPUrlV2(colorId, productId);
                  }
                  newProduct.PDPUrl = PDPUrl;
                });
              }
            });
            newProduct.availableToPurchase = availableToPurchase;
            if (!availableToPurchase) {
              newProduct.purchaseError = purchaseErrors.PRODUCT_NOT_AVAILABLE;
            }
            return newProduct;
          });
          setOrder({ ...orderData, products: newProducts });
        } else {
          const newProducts = products.map(product => {
            const newProduct = { ...product };
            newProduct.availableToPurchase = false;
            newProduct.purchaseError = purchaseErrors.PRODUCT_NOT_AVAILABLE;
            return newProduct;
          });
          setOrder({ ...orderData, products: newProducts });
        }
      });
    } else {
      const newProducts = products.map(product => {
        const newProduct = { ...product };
        newProduct.availableToPurchase = false;
        if (brandConflict) newProduct.purchaseError = purchaseErrors.PRODUCT_BRAND_CONFLICT;
        if (catalogTypeConflict) newProduct.purchaseError = purchaseErrors.PRODUCT_ORDER_TYPE_CONFLICT;
        if (brandConflict && catalogTypeConflict) newProduct.purchaseError = purchaseErrors.PRODUCT_BRAND_ORDER_TYPE_CONFLICT;
        return newProduct;
      });
      setOrder({ ...orderData, products: newProducts });
    }
  };

  const getOrder = () => {
    setLoading(true);
    if (orderNo || orderRefNo) {
      setLoading(false);
      fenixIntegrationService.getOrderDetails(orderNo, orderRefNo).then(response => {
        if (response && response.data && response.success) {
          getProductRePurchaseStatus(response.data);
        }
      });
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <Layout>
      <SEO title={seoTitle} />
      {isLoading ? <Loader /> : ''}
      {
        isListNotEmpty(contents?.contents)
          ? contents.contents.map(content => (
            <Fragment key={`orderDetails-${content[KEY]}`}>
              <SanityWidgetComponent
                widgetType={content[TYPE]}
                content={content}
                getOrder={getOrder}
                preOrder={preOrder}
                order={order}
                type='ORDER_DETAILS'
                layoutDir='orderDetails'
              />
            </Fragment>
          )) : ''
      }
    </Layout>
  );
};
OrderDetail.propTypes = {
  pageProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrderDetail;

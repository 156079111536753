/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */
import React, { useState, useEffect, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import Loader from '@common_components/Loader';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import navigate from '@utils/navigate';
import { getOrderProposalById } from '@api/orderProposalService';
import { constants } from '@utils/constants';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import { isListNotEmpty } from '@utils/commonUtils';

const OrderProposalDetailsLayout = props => {
  const { allSanityPredefinedPage } = useStaticQuery(graphql`
   {
     allSanityPredefinedPage(filter: {pageType: {eq: "orderProposalDetails"}}) {
       nodes {
         _rawTranslations(resolveReferences: {maxDepth: 100})
         pageType
         mozcomLocationId
         mozcomBrandId
         title
         seoDetails {
           title
         }
       }
     }
   }
 `);
  const { TYPE, KEY } = constants;
  const [contents, setContent] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  const [orderProposal, setOrderProposal] = useState(null);
  const [orderProposalName, setOrderProposalName] = useState(null);

  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const content = getPageContent(allSanityPredefinedPage.nodes);
      setContent(getTranslatedPageContent(content));
      setSeoTitle(content?.seoDetails?.title || '');
    }
  }, [allSanityPredefinedPage, allSanityPredefinedPage?.nodes]);
  const { pageProps } = props;
  const location = pageProps?.location;
  const { parseUrl } = queryString;
  const { query } = parseUrl(location?.search || '');
  const { id } = query || {};
  const [isLoading, setLoading] = useState(false);

  const getOrderProposal = () => {
    if (id) {
      setLoading(true);
      getOrderProposalById(id).then(response => {
        if (response && response.data && response.success && isListNotEmpty(response.data?.shipments)) {
          setOrderProposal(response.data);
          setOrderProposalName(response.data.name);
          setLoading(false);
        } else {
          navigate('/order-proposals');
        }
      });
    } else {
      navigate('/order-proposals');
    }
  };

  useEffect(() => {
    getOrderProposal();
  }, [id]);
  return (
    <Layout>
      <SEO title={seoTitle} />
      {isLoading ? <Loader /> : ''}
      {
        isListNotEmpty(contents?.contents) && orderProposal && isListNotEmpty(orderProposal?.shipments)
          ? contents.contents.map(content => (
            <Fragment key={`orderDetails-${content[KEY]}`}>
              <SanityWidgetComponent
                widgetType={content[TYPE]}
                content={content}
                orderProposal={orderProposal}
                orderProposalName={orderProposalName}
              />
            </Fragment>
          )) : ''
      }
    </Layout>
  );
};
OrderProposalDetailsLayout.propTypes = {
  pageProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrderProposalDetailsLayout;

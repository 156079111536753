import React, { useState, useEffect, Fragment } from 'react';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { isListNotEmpty } from '@utils/commonUtils';
import { constants } from '@utils/constants';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';

const OrderConfirmationPage = props => {
  const { pageProps } = props;
  const confirmationDetails = pageProps?.location?.state;
  const { email } = confirmationDetails;
  const { allSanityPredefinedPage } = useStaticQuery(graphql`
  {
    allSanityPredefinedPage(filter: {pageType: {eq: "orderConfirmation"}}) {
      nodes {
        _rawTranslations(resolveReferences: {maxDepth: 10})
        pageType
        mozcomLocationId
        mozcomBrandId
        title
        seoDetails {
          title
        }
      }
    }
  }
`);
  const { TYPE, KEY } = constants;
  const [contents, setContent] = useState(null);
  const [title, setTitle] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const content = getPageContent(allSanityPredefinedPage?.nodes);
      setContent(getTranslatedPageContent(content));
      setTitle(content?.title || '');
      setSeoTitle(content?.seoDetails?.title || '');
    }
  }, [allSanityPredefinedPage, allSanityPredefinedPage?.nodes]);
  return (
    <Layout page='order-confirmation'>
      <SEO title={seoTitle} />
      {
        isListNotEmpty(contents?.contents)
          ? contents.contents.map(content => (
            <Fragment key={`orderConfirmation-${content[KEY]}`}>
              <SanityWidgetComponent
                widgetType={content[TYPE]}
                content={content}
                email={email}
                title={title}
              />
            </Fragment>
          ))
          : ''
      }
    </Layout>
  );
};
OrderConfirmationPage.propTypes = {
  pageProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrderConfirmationPage;

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * ImpersonateUser Container
 *
 * @author Ajay J A
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { logout, impersonatelogin } from '@utils/authV2';
import { withTrans } from '@i18n/withTrans';
import { useTranslation } from 'react-i18next';
import ImpersonateUser from '../layouts/ImpersonateUser';

const ImpersonateUserContainer = ({ availableSites, headerContents }) => {
  const { t } = useTranslation();
  const [impersonateUsername, setImpersonateUsername] = useState('');
  const [error, setError] = useState({ impersonateUsername: '', isValid: '' });
  const [submitting, setSubmitting] = useState(false);

  /**
   * This method is used to handle signout click
   *
   */
  const handleSignOut = event => {
    event.preventDefault();
    if (logout) {
      logout();
    }
  };

  /**
   * This method is used to handle submit form
   *
   * @param {Object} impersonateUsername
   */
  const handleSubmitForm = () => {
    setSubmitting(true);
    impersonatelogin(impersonateUsername).then(response => {
      setSubmitting(false);
      if (response && !response.success) {
        const { errorCode } = response;
        if (errorCode) {
          setError({ impersonateUsername: t(`login:${errorCode}`) });
        } else {
          setError({ impersonateUsername: t('login:USER_NOT_FOUND') });
        }
      } else {
        setError({ impersonateUsername: t('login:error_message_something_went_wrong') });
      }
    });
  };

  /**
   * This method is used to handle change text
   *
   * @param {Object} e
   */
  const handleChange = e => {
    setError({ impersonateUsername: '', isValid: '' });
    setImpersonateUsername(e.target.value);
  };

  /**
   * Tjis method is used to validate login form
   *
   * @returns
   */
  const validateForm = () => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = true;
    let usernameError = '';
    if (impersonateUsername === '' && isValid) {
      usernameError = t('login:error_message_field_mandatory');
      isValid = false;
    } else if (!regexp.test(impersonateUsername)) {
      usernameError = t('login:error_message_invalid_email_format');
      isValid = false;
    }
    setError({ impersonateUsername: usernameError });
    return isValid;
  };

  /**
   * This method is used to handle submit form
   *
   */
  const handleSubmit = event => {
    event.preventDefault();
    if (validateForm(impersonateUsername)) {
      handleSubmitForm();
    }
  };

  return (
    <>
      <ImpersonateUser
        handleSignOut={handleSignOut}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        error={error}
        impersonateUsername={impersonateUsername}
        submitting={submitting}
      />
    </>
  );
};
ImpersonateUserContainer.propTypes = {
  availableSites: PropTypes.arrayOf(PropTypes.any).isRequired,
  headerContents: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default withTrans(ImpersonateUserContainer);

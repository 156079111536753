/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import navigate from '@utils/navigate';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import { productSearch, searchProductsWithNotes } from '@api/productService';
import { getWishlistDetails, getWishlistItems, getAllWishlists } from '@api/wishlistService';
import { getUser } from '@utils/authV2';
import { isListNotEmpty } from '@utils/commonUtils';
import { wishlistTabs, constants, facetCodes } from '@utils/constants';
import { constructUrlBody, urlConstructor, objectConstructor, constructSearchRequestBody, getRelativePath } from '@utils/urlParser';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import Loader from '@common_components/Loader';
import { getPageContent, getTranslatedPageContent, getFacets } from '@utils/contentUtils';

const getIntialData = () => {
  const { allSanityPredefinedPage, allAvailableFacets } = useStaticQuery(graphql`
  {
    allSanityPredefinedPage(filter: {pageType: {eq: "wishlistDetails"}}) {
      nodes {
        _rawTranslations(resolveReferences: {maxDepth: 100})
        pageType
        mozcomLocationId
        mozcomBrandId
        title
        seoDetails {
          title
        }
        pageSpecificProperties {
          contentLimit
        }
      }
    }
    allAvailableFacets {
      nodes {
        mozcomLocationId
        data {
          facetName
          code
          facetValues {
            id
            name
          }
        }
      }
    }
}      
`);
  const allFacets = [];
  const filteredFacets = getFacets(allAvailableFacets?.nodes);
  filteredFacets?.forEach(each => {
    allFacets.push(each.data);
  });
  let contents;
  let seoTitle;
  let contentLimit;
  if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
    const pageContents = getPageContent(allSanityPredefinedPage.nodes);
    contents = getTranslatedPageContent(pageContents);
    seoTitle = pageContents?.seoDetails?.title || '';
    contentLimit = pageContents?.pageSpecificProperties?.contentLimit;
  }
  return {
    allFacets,
    contents: contents?.contents,
    seoTitle,
    contentLimit,
  };
};

const WishlistEdit = props => {
  const { pageProps } = props;
  const { location } = pageProps;
  const queryString = location?.search || '';
  const queryObject = objectConstructor(queryString);
  const { id } = queryObject;

  const { allFacets, contents, seoTitle, contentLimit } = getIntialData();
  const { TYPE, KEY } = constants;
  const productsPerPage = (contentLimit && parseInt(contentLimit, 10) > 0) ? parseInt(contentLimit, 10) : 24;
  //local States
  const [searchResults, setSearchResults] = useState({
    aggregations: [],
    responseObjects: [],
    totalCount: 0,
    sorts: [],
  });
  const [selectedPage, setPage] = useState(0);
  const [selectedAggregations, setSelectedAggregations] = useState(null);
  const [selectedSort, setSort] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedWishlist, setWishlist] = useState(null);
  const [availableWishlists, setAvailableWishlists] = useState([]);
  const [isFilterModalOpen, setFilterModalFlag] = useState(false);
  const [wishlistFilterTab, setWishlistFilterTab] = useState(wishlistTabs.WISHLIST);
  const [isUpdated, setUpdated] = useState(false);
  const [hideUnavailable, setHideUnavailable] = useState(false);
  const [hasNotes, setNotesFlag] = useState(false);

  const toggleFilterModal = () => setFilterModalFlag(!isFilterModalOpen);

  const scrollToTop = () => {
    if (window) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const formatAggregations = aggregations => {
    const relevanceIndex = aggregations.findIndex(each => each.code === facetCodes.RELEVANCE);
    if (relevanceIndex < 0) {
      return aggregations.sort((a, b) => a.displayOrder - b.displayOrder);
    }
    const relevances = aggregations[relevanceIndex];
    if (relevances && isListNotEmpty(relevances.facetValues)) {
      const newRelevanceFacetValues = relevances.facetValues.map(value => ({ ...value, name: value.name.replace(/([A-Z])/g, ' $1').trim() }));
      const newRelevances = { ...relevances, facetValues: newRelevanceFacetValues };
      aggregations.splice(relevanceIndex, 1, newRelevances);
    }
    return aggregations.sort((a, b) => a.displayOrder - b.displayOrder);
  };

  const saveSearchResults = searchResult => {
    const { responseObjects, aggregations: unSortedAggregation, totalCount, sorts } = searchResult || {};
    const sortedAggregations = formatAggregations(unSortedAggregation || []);
    setSearchResults({
      aggregations: sortedAggregations || [],
      responseObjects: responseObjects || [],
      totalCount: totalCount || 0,
      sorts: sorts || [],
    });
  };

  const searchProducts = (requestBody, hasNotesFlag) => {
    scrollToTop();
    if (hasNotesFlag) {
      setLoading(true);
      const userDetails = getUser();
      searchProductsWithNotes({ ...requestBody, customerAccount: userDetails?.selectedAccountUniqueId }).then(resposne => {
        setLoading(false);
        if (resposne && resposne.success && resposne.data) {
          saveSearchResults(resposne.data);
        } else {
          saveSearchResults(null);
        }
      });
    } else {
      setLoading(true);
      productSearch(requestBody).then(response => {
        setLoading(false);
        if (response && response.success && response.data) {
          saveSearchResults(response.data);
        } else {
          saveSearchResults(null);
        }
      });
    }
  };

  const searchWishlistProducts = (wishlistId, requestBody, hasNotesFlag) => {
    scrollToTop();
    setLoading(true);
    const userDetails = getUser();
    getWishlistItems(wishlistId, { ...requestBody, customerAccount: userDetails?.selectedAccountUniqueId, hasNotes: hasNotesFlag }).then(resposne => {
      setLoading(false);
      if (resposne && resposne.success && resposne.data) {
        saveSearchResults(resposne.data);
      } else {
        saveSearchResults(null);
      }
    });
  };

  const updateUrl = filterData => {
    const filterObject = constructUrlBody(filterData);
    const constructedQuery = urlConstructor(filterObject);
    const pathname = getRelativePath(location.pathname) || '';
    const url = `${pathname}?${constructedQuery}`;
    navigate(url);
  };

  const getProducts = (aggregations, page, sort, hasNotesFlag, hideOutOfStock, selectedTab) => {
    const requestBody = {
      attributes: aggregations,
      page,
      sort,
      hideOutOfStock,
      size: productsPerPage,
    };
    if (selectedTab === wishlistTabs.WISHLIST && id) {
      setUpdated(true);
      setSelectedAggregations(aggregations);
      setPage(page);
      setSort(sort);
      setNotesFlag(hasNotesFlag);
      setHideUnavailable(hideOutOfStock);
      searchWishlistProducts(id, requestBody, hasNotesFlag);
    }
    if (selectedTab === wishlistTabs.ALL_PRODUCTS) {
      setUpdated(true);
      setSelectedAggregations(aggregations);
      setPage(page);
      setSort(sort);
      setNotesFlag(hasNotesFlag);
      setHideUnavailable(hideOutOfStock);
      searchProducts(requestBody, hasNotesFlag);
    }
  };

  const onApplyFilter = (aggregations, page, sort, hasNotesFlag, hideOutOfStock) => {
    updateUrl({
      page,
      sort,
      aggregations,
      id,
      hasNotes: hasNotesFlag,
      tab: wishlistFilterTab,
      hideUnavailable: hideOutOfStock,
    });
    getProducts(aggregations, page, sort, hasNotesFlag, hideOutOfStock, wishlistFilterTab);
  };
  const getWishList = wishlistId => {
    getWishlistDetails(wishlistId).then(response => {
      if (response && response.success && response.data) {
        setWishlist(response.data);
      }
    });
  };

  const handleTabChange = selectedTab => {
    updateUrl({
      id,
      tab: selectedTab,
    });
    getProducts(null, 0, null, false, false, selectedTab);
    setWishlistFilterTab(selectedTab);
  };

  const getWishlists = () => {
    getAllWishlists().then(response => {
      if (response && response.success && isListNotEmpty(response.data)) {
        setAvailableWishlists(response.data);
      }
    });
  };

  useEffect(() => {
    if (!isUpdated) {
      constructSearchRequestBody(queryObject, allFacets).then(requestBody => {
        const { page, sort, attributes, tab, hasNotes: hasNotesFlag, hideOutOfStock } = requestBody;
        setWishlistFilterTab(tab || wishlistTabs.WISHLIST);
        getProducts(attributes, page || 0, sort, hasNotesFlag, hideOutOfStock, tab || wishlistTabs.WISHLIST);
        setUpdated(false);
      });
    }
  }, [queryString]);

  useEffect(() => {
    getWishList(id);
    getWishlists();
  }, [id]);

  useEffect(() => {
    let filterApplied = false;
    if (isListNotEmpty(selectedAggregations)) {
      filterApplied = selectedAggregations.some(each => isListNotEmpty(each?.facetValues));
    }
    setIsFilterApplied(filterApplied);
  }, [selectedAggregations]);

  return (
    <Layout>
      <SEO title={seoTitle} />
      {isLoading ? <Loader /> : ''}
      <div className='plp-layout-outer top-shadow'>
        <div className={isLoading ? 'd-none' : ''}>
          {
            isListNotEmpty(contents)
              ? contents.map(content => (
                <Fragment key={`wishlist-edit-${content[KEY]}`}>
                  <SanityWidgetComponent
                    widgetType={content[TYPE]}
                    content={content}
                    type='WISHLIST_EDIT'
                    layoutDir='wishlistEdit'
                    searchResults={searchResults}
                    onApplyFilter={onApplyFilter}
                    selectedAggregations={selectedAggregations}
                    selectedPage={selectedPage}
                    productsPerPage={productsPerPage}
                    isFilterApplied={isFilterApplied}
                    selectedWishlist={selectedWishlist}
                    availableWishlists={availableWishlists}
                    wishlistFilterTab={wishlistFilterTab}
                    handleTabChange={handleTabChange}
                    isFilterModalOpen={isFilterModalOpen}
                    toggleFilterModal={toggleFilterModal}
                    getWishlists={getWishlists}
                    selectedSort={selectedSort}
                    availableSorts={searchResults?.sorts}
                    totalCount={searchResults?.totalCount}
                    products={searchResults?.responseObjects}
                    aggregations={searchResults?.aggregations}
                    isEdit
                    selectedWishlistName={selectedWishlist?.name}
                    selectedWishlistId={selectedWishlist?.id}
                    isWishlistEdit={wishlistFilterTab === wishlistTabs.WISHLIST}
                    hasNotes={hasNotes}
                    hideUnavailable={hideUnavailable}
                  />
                </Fragment>
              ))
              : ''
          }
        </div>
      </div>
    </Layout>
  );
};
WishlistEdit.propTypes = {
  pageProps: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default WishlistEdit;

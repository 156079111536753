import React from 'react';
import { useTranslation } from 'react-i18next';
import SubAccount from '@widgets/SubAccount';
import SEO from '@widgets/SEO';

const SubAccountPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title='SubAccounts' />
      <SubAccount t={t} />
    </>
  );
};

export default SubAccountPage;

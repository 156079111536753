/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 *
 */
import Login from '@src/widgets/Login';
import { isListNotEmpty } from '@utils/commonUtils';
import { constants } from '@utils/constants';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import SEO from '@widgets/SEO';
import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment, useEffect, useState } from 'react';

const LoginPage = () => {
  const { allSanityRetailerPage, allAvailableLocations, allSanityHeader } = useStaticQuery(graphql`
    {
      allSanityRetailerPage(filter: {pageType: {eq: "login"}}) {
        nodes {
          id
          title
          mozcomRetailerId
          _rawTranslations(resolveReferences: {maxDepth: 100})
          seoDetails {
            title
          }
          _type
          pageType
        }
      }
      allAvailableLocations {
        nodes {
          data {
            brandId
            locationId
            brandName
            locationName
            storefrontRoute
            locale
            currencyDisplay
            market
          }
        }
      }
      allSanityHeader {
        nodes {
          mozcomLocationId
          mozcomBrandId
          _rawTranslations(resolveReferences: {maxDepth: 10})
        }
      }
    }
  `);
  const [contents, setContents] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  const [title, setTitle] = useState('');
  const { TYPE, KEY } = constants;
  const [availableSites, setAvailableSite] = useState([]);
  const [headerContents, setHeaderContents] = useState([]);
  useEffect(() => {
    if (isListNotEmpty(allAvailableLocations?.nodes)) {
      const sites = allAvailableLocations.nodes.map(each => each.data);
      setAvailableSite(sites);
    }
  }, [allAvailableLocations, allAvailableLocations?.nodes]);

  useEffect(() => {
    if (isListNotEmpty(allSanityRetailerPage?.nodes)) {
      const localContent = getPageContent(allSanityRetailerPage?.nodes);
      setContents(getTranslatedPageContent(localContent));
      setSeoTitle(localContent?.seoDetails?.title || '');
      setTitle(localContent?.title);
    }
  }, [allSanityRetailerPage, allSanityRetailerPage?.nodes]);

  useEffect(() => {
    setHeaderContents(allSanityHeader?.nodes || []);
  }, [allSanityHeader, allSanityHeader?.nodes]);
  return (
    <>
      <SEO title={seoTitle} />
      {
        isListNotEmpty(contents?.contents)
          ? contents.contents.map(content => (
            <Fragment key={`login-${content[KEY]}`}>
              <SanityWidgetComponent
                widgetType={content[TYPE]}
                content={content}
                title={title}
                availableSites={availableSites}
                headerContents={headerContents}
              />
            </Fragment>
          )) : (
            <Login
              availableSites={availableSites}
              headerContents={headerContents}
            />
          )
      }
    </>
  );
};

export default LoginPage;

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */

import { isListNotEmpty } from '@utils/commonUtils';
import ImpersonateUser from '@widgets/ImpersonateUser';
import SEO from '@widgets/SEO';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';

const ImpersonateUserPage = () => {
  const { allAvailableLocations, allSanityHeader } = useStaticQuery(graphql`
  {
    allAvailableLocations {
      nodes {
        data {
          brandId
          locationId
          brandName
          locationName
          storefrontRoute
          locale
          currencyDisplay
          siteId
          market
        }
      }
    }
    allSanityHeader {
      nodes {
        mozcomLocationId
        mozcomBrandId
        _rawTranslations(resolveReferences: {maxDepth: 10})
      }
    }
  }
`);

  const [availableSites, setAvailableSite] = useState([]);
  const [headerContents, setHeaderContents] = useState([]);

  useEffect(() => {
    if (isListNotEmpty(allAvailableLocations?.nodes)) {
      const sites = allAvailableLocations.nodes.map(each => each.data);
      setAvailableSite(sites);
    }
  }, [allAvailableLocations, allAvailableLocations?.nodes]);

  useEffect(() => {
    setHeaderContents(allSanityHeader?.nodes || []);
  }, [allSanityHeader, allSanityHeader?.nodes]);

  return (
    <>
      <SEO title='Select User' />
      <ImpersonateUser availableSites={availableSites} headerContents={headerContents} />
    </>
  );
};

export default ImpersonateUserPage;

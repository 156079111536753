import React, { Fragment, useEffect, useState } from 'react';
import queryString from 'query-string';
import { graphql, useStaticQuery } from 'gatsby';
import ResetPassword from '@widgets/ResetPassword';
import SEO from '@widgets/SEO';
import PropTypes from 'prop-types';
import { withTrans } from '@i18n/withTrans';
import customerAccountServices from '@api/customerAccountServices';
import Loader from '@common_components/Loader';
import { isListNotEmpty } from '@utils/commonUtils';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { constants } from '@utils/constants';
import { SanityWidgetComponent } from '@src/utils/DynamicComponentConstructor';

const ResetPasswordPage = ({ location }) => {
  const { allSanityRetailerPage } = useStaticQuery(graphql`
  {
    allSanityRetailerPage(filter: {pageType: {eq: "resetPassword"}}) {
      nodes {
        id
        title
        mozcomRetailerId
        _rawTranslations(resolveReferences: {maxDepth: 100})
        seoDetails {
          title
        }
        _type
        pageType
      }
    }
  }
`);

  const { token } = queryString.parse(location?.search);
  const [isLoading, setLoading] = useState(false);
  const [isTokenValid, setTokenValid] = useState(false);
  const [contents, setContents] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  const { TYPE, KEY } = constants;

  useEffect(() => {
    if (token) {
      setLoading(true);
      customerAccountServices.forgotPasswordValidateToken(token).then(response => {
        setLoading(false);
        if (response && response.data) {
          setTokenValid(true);
        }
      }).catch(() => {
        setLoading(false);
      });
    }
  }, [token]);

  useEffect(() => {
    if (isListNotEmpty(allSanityRetailerPage?.nodes)) {
      const localContent = getPageContent(allSanityRetailerPage?.nodes);
      setContents(getTranslatedPageContent(localContent));
      setSeoTitle(localContent?.seoDetails?.title || '');
    }
  }, [allSanityRetailerPage?.nodes]);

  return (
    <>
      <SEO title={seoTitle || 'Reset Password'} />
      {isLoading ? <Loader /> : ''}
      {!isLoading && (
        isListNotEmpty(contents?.contents)
          ? contents.contents.map(content => (
            <Fragment key={`reset-password-${content[KEY]}`}>
              <SanityWidgetComponent
                widgetType={content[TYPE]}
                content={content}
                token={token}
                isTokenValid={isTokenValid}
              />
            </Fragment>
          )) : <ResetPassword token={token} isTokenValid={isTokenValid} />
      )}
    </>
  );
};
ResetPasswordPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withTrans(ResetPasswordPage);

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React, { useState, useEffect, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import { getAllWishlists } from '@api/wishlistService';
import { getUser } from '@utils/authV2';
import Loader from '@common_components/Loader';
import { isListNotEmpty } from '@utils/commonUtils';
import { constants } from '@utils/constants';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { storeWishlistDetailsInLocalStorage } from '@src/config/config';
import { UserData } from '@src/utils/localStorageUtils';

const WishList = () => {
  const { allSanityPredefinedPage } = useStaticQuery(graphql`
  {
    allSanityPredefinedPage(filter: {pageType: {eq: "wishlist"}}) {
      nodes {
        _rawTranslations(resolveReferences: {maxDepth: 100})
        pageType
        mozcomLocationId
        mozcomBrandId
        title
        seoDetails {
          title
        }
      }
    }
  }
`);
  const [availableWishlists, setAvailableWishlists] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getWishlists = () => {
    const userDetails = getUser();
    if (userDetails) {
      setLoading(true);
      // get wishlist from the local storage
      if (storeWishlistDetailsInLocalStorage) {
        const wishListFromLocalStorage = JSON.parse(UserData.getWishLists());
        if (wishListFromLocalStorage) {
          setLoading(false);
          setAvailableWishlists(wishListFromLocalStorage);
        }
      }
      getAllWishlists().then(response => {
        setLoading(false);
        if (response && response.success) {
          setAvailableWishlists(response.data);
        }
      });
    }
  };

  useEffect(() => {
    getWishlists();
  }, []);

  const { TYPE, KEY } = constants;
  const [contents, setContent] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const content = getPageContent(allSanityPredefinedPage.nodes);
      setContent(getTranslatedPageContent(content));
      setSeoTitle(content?.seoDetails?.title || '');
    }
  }, [allSanityPredefinedPage?.nodes]);
  return (
    <Layout>
      <SEO title={seoTitle} />
      {isLoading ? <Loader /> : ''}
      <div className={isLoading ? 'd-none' : ''}>
        <div class='wishlist-layout-outer top-shadow bg-dark-snow'>
          {
            isListNotEmpty(contents?.contents)
              ? contents.contents.map(content => (
                <Fragment key={`wishlist-${content[KEY]}`}>
                  <SanityWidgetComponent
                    widgetType={content[TYPE]}
                    content={content}
                    type='WISHLIST'
                    layoutDir='wishlist'
                    availableWishlists={availableWishlists}
                    getWishlists={getWishlists}
                  />
                </Fragment>
              ))
              : null
          }
        </div>
      </div>
    </Layout>
  );
};

export default WishList;

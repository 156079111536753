/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 *
 * @author Anokh J Ajai
 */
import React, { useState, useEffect, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { isListNotEmpty } from '@utils/commonUtils';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import { getUser } from '@utils/authV2';
import { constants } from '@utils/constants';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import { getPageContent, getTranslatedPageContent, getZendeskURL } from '@utils/contentUtils';
import { setCartToLocalStorage, setOrdersToLocalStorage, setWishListToLocalStorage, setOrderProposalToLocalStorage } from '@src/utils/storageUtils';
import { UserData } from '@src/utils/localStorageUtils';
import { getAllWishlistsNames } from '@src/apis/wishlistService';
import { initialReOrderContentCount } from '@src/config/config';

const HomePage = () => {
  const { allSanityPredefinedPage } = useStaticQuery(graphql`
  {
    allSanityPredefinedPage(filter: {pageType: {eq: "home"}}) {
      nodes {
        _rawTranslations(resolveReferences: {maxDepth: 100})
        pageType
        mozcomLocationId
        mozcomBrandId
        title
        pageSpecificProperties {
          zenDeskURL
        }
        seoDetails {
          title
        }
      }
    }
  }
`);
  const { TYPE, KEY } = constants;
  const [initialContent, setInitialContent] = useState(null);
  const [finalContent, setFinalContent] = useState(null);
  const [showAll, setShowAll] = useState(!initialReOrderContentCount > 0);
  const [seoTitle, setSeoTitle] = useState('');
  const [zenDeskURL, setZendeskURL] = useState('');
  const [parentIdPrefix, setParentIdPrefix] = useState();
  const [availableWishlists, setAvailableWishlists] = useState([]);
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const isCompeleteUrl = url.includes(UserData.getBaseUrl());
  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const content = getPageContent(allSanityPredefinedPage.nodes);
      const prefix = content?.mozcomLocationId
        ? `${content?.mozcomLocationId}-${content?.mozcomBrandId}`
        : `brand-default-${content?.mozcomBrandId}`;
      setParentIdPrefix(prefix);
      const contents = getTranslatedPageContent(content);
      const initialContentList = [];
      const finalContentList = [];
      if (isListNotEmpty(contents?.contents) && contents?.contents.length > 0) {
        contents?.contents.forEach(contentElement => {
          if (initialContentList.length < initialReOrderContentCount) initialContentList.push(contentElement);
          else finalContentList.push(contentElement);
        });
      }
      setInitialContent(initialContentList);
      setFinalContent(finalContentList);
      setSeoTitle(content?.seoDetails?.title || '');
      setZendeskURL(getZendeskURL(content, allSanityPredefinedPage?.nodes));
    }
  }, [allSanityPredefinedPage, allSanityPredefinedPage?.nodes]);

  const getOrders = () => {
    const { selectedAccountUniqueId } = getUser() || {};
    if (selectedAccountUniqueId) {
      setOrdersToLocalStorage(selectedAccountUniqueId);
    }
  };

  const getWishlists = () => {
    getAllWishlistsNames().then(response => {
      if (response && response.success && isListNotEmpty(response.data)) {
        setAvailableWishlists(response.data);
      }
    });
  };

  useEffect(() => {
    if (isCompeleteUrl) {
      getOrders();
      getWishlists();
      setCartToLocalStorage();
      setWishListToLocalStorage();
      setOrderProposalToLocalStorage();
    }
  }, []);

  const showAllComponents = () => {
    if (!showAll) {
      setShowAll(true);
      UserData.setPerformance('NORMAL');
    }
  };

  useEffect(() => {
    UserData.clearPerformance();
  }, []);

  return (
    <div onMouseEnter={showAllComponents} onScroll={showAllComponents} onMouseOver={showAllComponents}>
      <Layout>
        {showAll && <SEO title={seoTitle} zenDeskURL={zenDeskURL} />}
        {isListNotEmpty(initialContent) ? initialContent.map(content => (
          <Fragment key={`home-${content[KEY]}`}>
            <SanityWidgetComponent
              widgetType={content[TYPE]}
              content={content}
              availableWishlists={availableWishlists}
              getWishlists={getWishlists}
              parentId={parentIdPrefix ? `${parentIdPrefix}-${content[KEY]}` : ''}
            />
          </Fragment>
        )) : ''}
        {isListNotEmpty(finalContent) && (showAll ? finalContent.map(content => (
          <Fragment key={`home-${content[KEY]}`}>
            <SanityWidgetComponent
              widgetType={content[TYPE]}
              content={content}
              availableWishlists={availableWishlists}
              getWishlists={getWishlists}
              parentId={parentIdPrefix ? `${parentIdPrefix}-${content[KEY]}` : ''}
            />
          </Fragment>
        )) : '')}
      </Layout>
    </div>
  );
};

export default HomePage;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */

import React, { useState, useEffect, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import Loader from '@common_components/Loader';
import fenixIntegrationService from '@api/fenixIntegrationService';
import { getUser } from '@utils/authV2';
import { constants } from '@utils/constants';
import { isListNotEmpty } from '@utils/commonUtils';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { UserData } from '@src/utils/localStorageUtils';
import { storeOrderDetailsInLocalStorage } from '@src/config/config';

const OrderHistoryLayout = () => {
  const { allSanityPredefinedPage } = useStaticQuery(graphql`
  {
    allSanityPredefinedPage(filter: {pageType: {eq: "orderHistory"}}) {
      nodes {
        _rawTranslations(resolveReferences: {maxDepth: 100})
        pageType
        mozcomLocationId
        mozcomBrandId
        title
        seoDetails {
          title
        }
      }
    }
  }
`);

  const [availableOrders, setAvailableOrders] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const getOrderHistoryDetails = selectedAccount => {
    setLoading(true);
    // get orders from the local storage
    if (storeOrderDetailsInLocalStorage) {
      const ordersFromLocalStorage = JSON.parse(UserData.getOrders());
      if (ordersFromLocalStorage) {
        setLoading(false);
        setAvailableOrders(ordersFromLocalStorage);
      }
    }
    fenixIntegrationService.getOrderHistory(selectedAccount).then(response => {
      setLoading(false);
      if (response && isListNotEmpty(response.data)) {
        setAvailableOrders(response.data.sort((a, b) => (new Date(b.orderDate) - new Date(a.orderDate))));
      }
    });
  };
  const getOrders = () => {
    setLoading(true);
    const { selectedAccountUniqueId } = getUser() || {};
    if (selectedAccountUniqueId) {
      getOrderHistoryDetails(selectedAccountUniqueId);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const { TYPE, KEY } = constants;
  const [contents, setContent] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const content = getPageContent(allSanityPredefinedPage.nodes);
      setContent(getTranslatedPageContent(content));
      setSeoTitle(content?.seoDetails?.title || '');
    }
  }, [allSanityPredefinedPage?.nodes]);
  return (
    <Layout>
      <SEO title={seoTitle} />
      {isLoading ? <Loader /> : ''}
      <div className={isLoading ? 'd-none' : ''}>
        {
          isListNotEmpty(contents?.contents)
            ? contents.contents.map(content => (
              <Fragment key={`order-history-${content[KEY]}`}>
                <SanityWidgetComponent
                  widgetType={content[TYPE]}
                  content={content}
                  availableOrders={availableOrders}
                  getOrders={getOrders}
                  getOrderHistoryDetails={getOrderHistoryDetails}
                />
              </Fragment>
            ))
            : null
        }
      </div>
    </Layout>
  );
};

export default OrderHistoryLayout;

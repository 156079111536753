/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * Cart
 *
 * @author Anokh J Ajai
 */

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import orderService from '@api/orderService';
import Layout from '@widgets/Layout';
import SEO from '@widgets/SEO';
import Loader from '@common_components/Loader';
import { isListNotEmpty, isPreOrder } from '@utils/commonUtils';
import { constants } from '@utils/constants';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import { getPageContent, getTranslatedPageContent } from '@utils/contentUtils';
import { useStaticQuery, graphql } from 'gatsby';
import { UserData } from '@utils/localStorageUtils';
import { storeCartDetailsInLocalStorage } from '@src/config/config';
import { setCartToLocalStorage, setPreorderCartToLocalStorage } from '@src/utils/storageUtils';

const CartPage = props => {
  const { allSanityPredefinedPage, allSanityBrand } = useStaticQuery(graphql`
  {
    allSanityPredefinedPage(filter: {pageType: {eq: "cart"}}) {
      nodes {
        _rawTranslations(resolveReferences: {maxDepth: 10})
        pageType
        mozcomLocationId
        mozcomBrandId
        title
        seoDetails {
          title
        }
      }
    }
    allSanityBrand {
      nodes {
        mozcomBrandId
        title
        brandLogo {
          asset {
            url
          }
        }
      }
    }
  }
`);
  const { location } = props;
  const [order, setOrder] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [hasItems, setItemsFlag] = useState(false);
  const { TYPE, KEY } = constants;
  const [contents, setContent] = useState(null);
  const [seoTitle, setSeoTitle] = useState('');
  const [cartDeadlines, setCartDeadlines] = useState(null);
  const [cartEligibilityErrors, setCartEligibilityErrors] = useState(null);
  const [disableCheckOut, setDisableCheckout] = useState(false);
  const [displayCartRules, setDisplayHardRule] = useState(false);
  const [isCartLoaded, setCartLoaded] = useState(false);
  useEffect(() => {
    if (isListNotEmpty(allSanityPredefinedPage?.nodes)) {
      const content = getPageContent(allSanityPredefinedPage?.nodes);
      setContent(getTranslatedPageContent(content));
      setSeoTitle(content?.seoDetails?.title || '');
    }
  }, [allSanityPredefinedPage, allSanityPredefinedPage?.nodes]);

  const populateBrandLogosToShipments = shipments => {
    if (isListNotEmpty(allSanityBrand?.nodes)) {
      const userData = UserData.getUser();
      const { accessibleBrands } = userData || {};
      if (isListNotEmpty(accessibleBrands)) {
        const newBrands = allSanityBrand?.nodes;
        if (isListNotEmpty(shipments)) {
          const newShipments = shipments.map(shipment => {
            if (isListNotEmpty(newBrands)) {
              const brandData = newBrands.find(brand => brand.mozcomBrandId === shipment.brand);
              return ({ ...shipment, logoUrl: brandData?.brandLogo?.asset?.url });
            }
            return shipment;
          });
          return newShipments;
        }
      }
    }
    return shipments;
  };

  const checkCartEligibility = async () => (
    orderService.checkCartEligibility().then(response => {
      if (response && response.success && response.data) {
        if (!response.data?.hasErrors) {
          setDisplayHardRule(true);
          if (response.data.cartRuleErrorMessages) {
            setCartEligibilityErrors(response.data.cartRuleErrorMessages);
            if (isListNotEmpty(response.data.cartRuleErrorMessages.HARD)) {
              setDisableCheckout(true);
            }
          }
        }
        return response.data;
      }
      return null;
    }));

  const updateCartDetails = data => {
    const upadtedShipments = populateBrandLogosToShipments(data?.shipments);
    const hasOrderItem = isListNotEmpty(upadtedShipments)
      && upadtedShipments.some(shipment => shipment.lineItems?.length > 0);
    setItemsFlag(hasOrderItem);
    const updatedOrder = { ...data, shipments: upadtedShipments };
    setOrder(updatedOrder);
  };

  const refreshOrder = async () => {
    setLoading(true);
    if (storeCartDetailsInLocalStorage) {
      //Fetching cart details from Local Storage
      const cachedCartDetails = UserData.getProductsInCart();
      if (cachedCartDetails && isListNotEmpty(cachedCartDetails?.shipments) && cachedCartDetails?.priceInfo) {
        updateCartDetails(cachedCartDetails);
        setLoading(false);
      }
    }
    return orderService.getCartOrder().then(response => {
      setLoading(false);
      if (response && response.success && response.data) {
        updateCartDetails(response.data);
        setCartLoaded(true);
        //Storing cart details to Local Storage
        setCartToLocalStorage(response.data);
        if (!response.data?.hasErrors) {
          setDisplayHardRule(true);
          if (response.data.cartRuleErrorMessages) {
            setCartEligibilityErrors(response.data.cartRuleErrorMessages);
            if (isListNotEmpty(response.data.cartRuleErrorMessages.HARD)) {
              setDisableCheckout(true);
            }
          }
        }
      } else {
        setItemsFlag(false);
      }
      return response;
    }).catch(error => {
      console.log(error);
      window.location.reload();
    });
  };

  const getPreOrderCart = async () => {
    setLoading(true);
    if (storeCartDetailsInLocalStorage) {
      //Fetching cart details from Local Storage
      const cachedCartDetails = UserData.getProductsInCart();
      if (cachedCartDetails && isListNotEmpty(cachedCartDetails?.shipments) && cachedCartDetails?.priceInfo) {
        setOrder(cachedCartDetails);
        const hasItemsLocal = cachedCartDetails.shipments.some(shipment => isListNotEmpty(shipment.lineItems));
        setItemsFlag(hasItemsLocal);
        setLoading(false);
      }
    }
    return orderService.getPreOrderCartWithApplicableDates().then(response => {
      setLoading(false);
      if (response && response.success && response.data && isListNotEmpty(response.data.shipments)) {
        setPreorderCartToLocalStorage(response.data);
        setCartLoaded(true);
        setOrder(response.data);
        const hasItemsLocal = response.data.shipments.some(shipment => isListNotEmpty(shipment.lineItems));
        setItemsFlag(hasItemsLocal);
        if (!response.data?.hasErrors) {
          setDisplayHardRule(true);
          if (response.data.cartRuleErrorMessages) {
            setCartEligibilityErrors(response.data.cartRuleErrorMessages);
            if (isListNotEmpty(response.data.cartRuleErrorMessages.HARD)) {
              setDisableCheckout(true);
            }
          }
        }
      } else {
        setOrder(null);
        setItemsFlag(false);
      }
      return response;
    }).catch(error => {
      console.log(error);
      window.location.reload();
    });
  };

  const updateOrder = newOrder => {
    const upadtedShipments = populateBrandLogosToShipments(newOrder?.shipments);
    const updatedOrder = { ...newOrder, shipments: upadtedShipments };
    setOrder(updatedOrder);
    setItemsFlag(isListNotEmpty(newOrder?.lineItems));
  };

  const getShipmentDeadlineForCart = productId => {
    if (productId != null) {
      orderService.getApplicableShipmentDates(productId).then(response => {
        if (response && response.success && response.data) {
          setCartDeadlines(response.data);
        }
        return response;
      });
    } else {
      orderService.getShipmentDeadlineForCart().then(response => {
        if (response && response.success && response.data) {
          setCartDeadlines(response.data);
        }
        return response;
      });
    }
  };

  const getCart = async () => {
    if (isPreOrder()) {
      await getShipmentDeadlineForCart();
      return getPreOrderCart();
    }
    return refreshOrder();
  };

  useEffect(() => {
    setLoading(true);
    getCart();
  }, []);

  return (
    <Layout page={hasItems ? 'cart' : ''}>
      <SEO title={seoTitle} />
      {isLoading ? <Loader /> : ''}
      <div className={isLoading ? 'd-none' : ''}>
        {
          isListNotEmpty(contents?.contents)
            ? contents.contents.map(content => (
              <Fragment key={`cart-${content[KEY]}`}>
                <SanityWidgetComponent
                  widgetType={content[TYPE]}
                  content={content}
                  location={location}
                  order={order}
                  updateOrder={updateOrder}
                  refreshOrder={getCart}
                  isCartLoaded={isCartLoaded}
                  //Pre order specific
                  getPreOrderCart={getPreOrderCart}
                  cartDeadlines={cartDeadlines}
                  setItemsFlag={setItemsFlag}
                  cartEligibilityErrors={cartEligibilityErrors}
                  disableCheckOutButton={disableCheckOut}
                  displayCartRules={displayCartRules}
                  checkCartEligibility={checkCartEligibility}
                  setDisableCheckout={setDisableCheckout}
                  setCartEligibilityErrors={setCartEligibilityErrors}
                  setDisplayHardRule={setDisplayHardRule}
                  getShipmentDeadlineForCart={getShipmentDeadlineForCart}
                />
              </Fragment>
            ))
            : ''
        }
      </div>
    </Layout>
  );
};
CartPage.defaultProps = {
  location: {},
};
CartPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
};
export default CartPage;
